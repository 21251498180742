.swiper {
  padding: 1rem;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}

.mySwiper {
  height: 400px;
  font-family: 'Manrope', Arial, sans-serif;
}

.sliderPage {
  font-family: 'Manrope', Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  max-width: 40ch;
  margin-bottom: 0.2rem;
}

.sliderPageList {
  text-align: left;
}

@media screen and (max-width: 600px) {
  .sliderPage {
    font-size: 0.8rem;
    max-width: 30ch;
  }
  .swiper-button-prev {
    scale: 50%;
  }
  .swiper-button-next {
    scale: 50%;
  }
}

.btn {
  background-color: #9f2943;
  border: none;
  border: 2px solid #9f2943;
  border-radius: 1.875rem;
  color: #f7f7f0;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding: 1rem 2.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
