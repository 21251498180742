.App {
  font-family: 'Manrope', Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.header {
  display: flex;
  flex-direction: column;

  justify-content: center;

  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 0 14px #00000042;
}

.site-header {
  display: flex;
  background: #9f2943;
  height: 2rem;
  padding-left: 1.5rem;
  align-items: center;
}

.area-navigation {
  display: flex;
  align-items: baseline;
}

.area-navigation a {
  border: none;

  color: #eee;

  font-size: 0.875rem;
  padding: 5px 16px;
  text-decoration: none;
  text-decoration-line: none;
  font-weight: 600;
}

.area-navigation a:hover {
  color: #fff;
}

a:visited {
  text-decoration: none;
}

.site-navigation {
  display: flex;
  flex-direction: column;
  height: 4rem;
  background: white;
  justify-content: center;
  padding-left: 1.5rem;
}

.site-navigation-row {
  height: auto;
}

.container h1 {
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  align-content: center;

  color: rgb(0, 0, 0);
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.bredband2-logo-svg {
  max-height: 2rem;
  max-width: 600px;
  margin: 5px;
}

.view-container {
  max-width: 600px;
}

.qrCodeTelia {
  border: none;
  height: 200px;
  width: 200px;
}

@media screen and (max-width: 600px) {
  .view-container {
    max-width: 300px;
    font-size: 0.8rem;
  }

  .bredband2-logo-svg {
    max-width: 150px;
  }

  .area-navigation {
    display: none;
  }

  .qrCodeTelia {
    scale: 70%;
  }
}
