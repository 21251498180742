.esim-header {
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 600px;
}

.esim-body {
  max-width: 45ch;
  margin-bottom: 0.2rem;
  font-size: 1rem;
}
