.loader-text {
  font-size: 1.4rem;
  text-align: center;
  width: 185px;
  margin-top: 0px;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.balls {
  text-align: center;
  width: 185px;
  margin: 5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ball {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(159 41 67);
  float: right;
  margin: 5px;
}

.balls > .bouncingball1 {
  animation-name: bouncy1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.balls > .bouncingball2 {
  animation-name: bouncy2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-delay: 1s;
}

.balls > .bouncingball3 {
  animation-name: bouncy3;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-delay: 2s;
}

@keyframes bouncy1 {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 25px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes bouncy2 {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 25px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes bouncy3 {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 25px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}
