@font-face {
  font-family: 'Manrope'; /*Can be any text*/
  src:
    local('ManropeRegular'),
    url('./fonts/manrope/static/Manrope-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ucc-btn {
  background-color: #9f2943;
  border: none;
  border: 2px solid #9f2943;
  border-radius: 1.875rem;
  color: #f7f7f0;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding: 1rem 2.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
input:valid {
  /* box-shadow: 0 0 5px 1px rgb(31, 198, 16); */
  box-shadow: none;
}

input:focus:valid {
  /* box-shadow: 0 0 5px 1px rgb(31, 198, 16); */
  box-shadow: none;
}

input:invalid {
  box-shadow: 0 0 5px 1px red;
}

input:focus:invalid {
  box-shadow: 0 0 5px 1px red;
}
